import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledContainer = styled('div')({
    height: '100vh',
    width: '80% !important',
    maxWidth: '100% !important',
    background: '#E4F1F8',
    paddingBottom: '35px',
    border: 'none',
    margin: 'auto',
    boxShadow: 'none',
    backgroundColor: '#E4F1F8 !important',
    paddingLeft: '76px'
  })
 export const StyledBox = styled('div')({
    display: 'flex',
    width: '100% !important',
    maxWidth: '100% !important',
    alignItems: 'center',
    paddingTop: '4rem',
    justifyContent: 'center',
     border: 'none',
    boxShadow: 'none',
    backgroundColor: '#E4F1F8 !important'
  })
 export const StyledPaper = styled('div')({
    display: 'flex',
    width: '100%',
  })

 export const FormSection = styled('form')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
      width: '40%',
      '@media (max-width: 900px)': {
        width: '100%',
      }
  })

 export const ImageSection = styled('div')({
    // flex: 1,
    backgroundPosition: 'center',
     paddingTop: '2rem',
    // display: 'flex',
    // paddingLeft: '9rem',
    width: '60%',
    // alignItems: 'center',
    // justifyContent: 'center',
    '@media (max-width: 900px)': {
      display: 'none'
    }
  })
 export const PageTitle = styled(Typography)({
    color: '#262626',
     fontSize: '2.25rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    paddingTop: '30px',
    fontFamily: 'MetaProBold'
  })
 export const StyleSubTitle = styled(Typography)({
    color: '#858585',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '6px',
  })

 export const StyledLable = styled(Typography)({
    color: '#5A607F',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '30px',
    paddingBottom: '6px',
  })
 export const ErrorText = styled(Typography)({
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '1px',
    position: 'absolute',
  })
 export const ImageBox = styled('img')({
    objectFit: 'contain',
   width: '84%',
   marginTop: '6rem',
   marginLeft: 'auto',
   display: 'block',
   '@media (max-width: 1100px)': {
     width: '70%',
   }
  })
