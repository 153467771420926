import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { IconButton, styled, Tooltip, tooltipClasses, type TooltipProps } from '@mui/material'

const IconWrapper = styled('div')({
  width: 24,
  height: 24,
  position: 'relative',
})
const ToolTipBox = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const IconCircle = styled('div')({
  width: 20,
  height: 20.01,
  left: 2,
  top: 2,
  position: 'absolute',
//   background: '#0179C1',
  borderRadius: '50%',
})

const PopoverContainer = styled('div')({
  width: 252,
  height: 58,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 12,
  paddingBottom: 12,

  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Text = styled('div')({
  width: 482,
  color: '#2C2C2C',
  fontSize: 14,
  fontFamily: 'calibri',
  fontWeight: '300',
  wordWrap: 'break-word',
})

const InfoComponent: React.FC = () => {
  return (
    <ToolTipBox
     placement="top"
     style={{ backgroundColor: 'none' }}
      title={
         <PopoverContainer >
          <Text>
            Access to CSCS Smart Check requires a company-provided login.<br />
            Personal account creation is not available.
          </Text>
         </PopoverContainer>
      }
      arrow
    >
      <IconButton style={{ padding: 0, paddingBottom: 7 }}>
        <IconWrapper>
          <IconCircle />
          <InfoIcon sx={{ color: 'white', position: 'absolute', top: 2, left: 2, }} style={{ color: '#0179C1' }} />
        </IconWrapper>
      </IconButton>
    </ToolTipBox>
  )
}

export default InfoComponent
