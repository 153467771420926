import React from 'react'

import NotFoundPage from './pages/404'
import Home from './pages/home'
import LoginPage from './pages/login'
import ProfilePage from './pages/profile'
import Report from './pages/report'
import Support from './pages/support'
import VerificationPage from './pages/verfication'

interface IRoutes {
  element: React.ReactNode
  secure?: boolean
  path: string
}

const routes: IRoutes[] = [
  {
    path: '/',
    secure: true,
    element: <Home />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/verification',
    element: <VerificationPage />,
  },
  {
    path: '/profile',
    secure: true,
    element: <ProfilePage />,
  },
  {
    path: '/support',
    element: <Support />,
  },
  {
    path: '/report-card',
    secure: true,
    element: <Report />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routes
