import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Popper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { isLogin, logOut } from 'src/utils/islogin'

const StyledButton = styled(Button)({
  width: '28px',
  height: '49px',
  minWidth: '51px',
  marginLeft: '53px',
  padding: 3.25,
  background: '#0179C1',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 10,
  '& .MuiTypography-root': {
    color: 'white',
    fontSize: 16.4,
    fontFamily: 'Inter',
    fontWeight: 400,
    lineHeight: '28.7px',
  },
})

const ProfilePopup = styled(Box)({
  width: 224,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.09)',
  borderRadius: 6,
  overflow: 'hidden',
  border: '1px solid #F1F5F9',
  display: 'inline-flex',
  flexDirection: 'column',
  background: 'white',
})

const PopupItem = styled(Button)({
  width: '100%',
  padding: '12px 8px',
  display: 'flex',
  textTransform: 'none',
  cursor: 'pointer',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  background: 'white',
  '& .MuiTypography-root': {
    color: '#334155',
    fontSize: 14,
    fontFamily: 'Calibri',
    fontWeight: 400,
    lineHeight: '20px',
  },
})
const PopupItemLabel = styled('div')({
  width: '100%',
  padding: '12px 8px',
  display: 'flex',
  textTransform: 'none',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 8,
  background: 'white',
  '& .MuiTypography-root': {
    color: '#334155',
    fontSize: 14,
    fontFamily: 'Calibri',
    fontWeight: 700,
    lineHeight: '20px',
  },
})
interface ProfileMenuProps {
  handleClickProfile?: () => void
  handleClickLogout?: () => void
  children?: React.ReactNode
}
const ProfileMenu: React.FC<ProfileMenuProps> = ({ handleClickProfile, handleClickLogout, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigation = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const handleLoginClick = () => {
    logOut()
  }
  return (
    <div style={{ position: 'relative' }}>
      {isLogin()
        ? <>
          <div style={{ position: 'relative', top: '5px', width: 40, height: 0, marginLeft: '2rem', marginTop: '8px', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '2px #0179C1 solid' }}></div>
          <StyledButton aria-describedby={id} onClick={handleClick}>
            <Typography style={{ marginTop: '7px' }}>
              <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className='mt-2'>
                <g fill="none" stroke="white" strokeWidth="2">
                  <line x1="5" y1="7" x2="25" y2="7" />
                  <line x1="5" y1="15" x2="25" y2="15" />
                  <line x1="5" y1="23" x2="25" y2="23" />
                </g>
              </svg>
            </Typography> </StyledButton>
        </>
        : <>
          <div style={{ width: 40, height: 0, marginLeft: '2rem', marginTop: '-5px', position: 'relative', top: '4px', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '2px #0179C1 solid' }}></div>
          <Button onClick={handleLoginClick} style={{
            marginTop: '0px',
            background: '#4696d1',
            marginLeft: '47px',
            color: 'white',
            fontSize: 16.4,
            fontWeight: 400,
            lineHeight: '28.7px'
          }}>Login</Button>
        </>}
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ProfilePopup>
          <PopupItemLabel>
            <Typography style={{ fontWeight: 700 }}>My Account</Typography>
          </PopupItemLabel>
          <Box borderBottom="1px solid #F1F5F9" />
          {isLogin() && <PopupItem onClick={handleClickProfile}>
            <img src='/svg/user.svg' alt="logout-button" />  <Typography>Profile</Typography>
          </PopupItem>}
          <Box borderBottom="1px solid #F1F5F9" />
          <PopupItem onClick={isLogin() ? handleClickLogout : () => { navigation('/login') }}>
            <img src='/svg/log-out.svg' alt="logout-button" /> <Typography>{isLogin() ? 'Logout' : 'Login'}</Typography>
          </PopupItem>
        </ProfilePopup>
        {children}
      </Popper>

    </div>
  )
}

export default ProfileMenu
