import axios, {
  AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import { isLogin } from 'src/utils/islogin'
import UrlPattern from 'url-pattern'

import store from '../redux'

import * as endpoints from './endpoints'
import { type ApiResponse } from './types'

interface IAxiosConfig extends AxiosRequestConfig {}

function generateEndpoint(url: keyof typeof endpoints, params?: object) {
  const pattern = new UrlPattern(endpoints[url])

  return pattern.stringify(params)
}

async function http(
  payload: any,
  config?: IAxiosConfig,
): Promise<ApiResponse> {
  try {
    const baseURL = payload.baseUrl || `${process.env.REACT_APP_API_BASE_URL}`
    const api = axios.create({
      baseURL,
    })

    const { data, query, path, method, authToken, userAuthToken } = payload

    const url = generateEndpoint(path.url, path.params)

    // get the access token from redux
    const token = store.getState()?.access_token.accessToken

    const axiosConfig: any = config || {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (isLogin() && userAuthToken) {
      axiosConfig.headers['User-Authorization'] = userAuthToken ? `Bearer ${userAuthToken}` : ''
    }

    const requestObject: AxiosRequestConfig = {
      headers: {
        Authorization: authToken || `Bearer ${token}`,
        ...axiosConfig.headers,
      },
      url,
      method,
    }

    if (method === 'GET' && query) {
      requestObject.params = query
    }

    if (method !== 'GET' && data) {
      requestObject.data = data
    }

    const response: AxiosResponse<any, any> = await api(requestObject)

    const responseSuccess = {
      response,
      error: null,
    }

    return responseSuccess
  } catch (error: any) {
    const { message = 'Internal server error' } = error || {}
    const responseError = {
      response: null,
      error: {
        message,
        status: 500,
      },
    }

    // If error is typeof axios error
    if (error instanceof AxiosError) {
      const { response } = error satisfies AxiosError
      const { data } = response || {}
      const { message: axiosResponseError } = data || {}
      responseError.error.message = axiosResponseError || message
      responseError.error.status = error.response?.status || 500

      return responseError
    }

    return responseError
  }
}

export default http
