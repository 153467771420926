import React from 'react'
import ReactCodeInput from 'react-code-input'
import styled from '@emotion/styled'
import { Stack, Typography } from '@mui/material'

  const ErrorText = styled(Typography
  )({
    color: 'red',
    fontSize: '0.875rem',
    marginTop: '2px',
    position: 'absolute',
    bottom: '-16px',
    paddingLeft: '3px'
  })
  const SuccessText = styled(Typography)({
    color: '#04C300',
    fontSize: '0.875rem',
    marginTop: '2px',
    position: 'absolute',
    bottom: '-16px',
    paddingLeft: '3px'
  })

interface IProps {
  fields?: number
  getValue: (value: string) => void
  message?: string
  status?: boolean
}
 const OtpVerificationInput: React.FC<IProps> = ({ fields = 8, getValue, message, status }) => {
    return (
        <Stack
            justifyContent="space-between"
            component="div"
            sx={{ width: '100%', position: 'relative' }}>
            <ReactCodeInput
                className="fae--code-input"
                inputStyle={{
                    border: '0.96px solid #ACACAC',
                    margin: '4px',
                    outline: 'none',
                    textAlign: 'center',
                    height: '41px',
                    paddingLeft: '4px',
                    width: '37px',
                    borderRadius: '5px',
                }}
                type={'text'}
                fields={fields}
                onChange={getValue}
                name="otp-input"
                inputMode="numeric"
            />
            {
                message && status ? <SuccessText>{message}</SuccessText>
                : <ErrorText>{message}</ErrorText>
            }
         </Stack>
    )
}
export default OtpVerificationInput
