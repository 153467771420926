import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, CircularProgress } from '@mui/material'
import Footer from 'src/components/Footer'
import Image from 'src/components/Image'
import Link from 'src/components/Link'
import OtpVerificationInput from 'src/components/OtpVerificationInput'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import { ExtraLinks } from 'src/utils/extraLinks'
import Cookies from 'universal-cookie'

import { VerifyToken } from './StateHandler/Actions'
import { setAsInitial } from './StateHandler/otp.reducer'
import { FormSection, ImageSection, LinkTextDiv, PageTitle, StyledBox, StyledContainer, StyledPaper, StyleSubTitle, Text } from './styles'

const Verification: React.FC<any> = () => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(''))
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const state: any = useAppSelect((state) => state.otpeReducer)
  const cookies = new Cookies()
  const location = useLocation()
  const handleChange = (element: any) => {
    setCode(element)
  }
  const handleSubmit = () => {
    setLoading(true)
    if (String(code).length < 8) {
      setMessage('Invalid code')
      setStatus(false)
      setLoading(false)
      return
    }
    dispatch(VerifyToken({ loginCode: String(code), username: decodeURIComponent(location?.search?.split('=')[1]) }))
  }
  useEffect(() => {
    if (state?.payload?.responseData?.data?.accessToken) {
      setLoading(state?.loading)
      cookies.set('token', state?.payload?.responseData?.data?.accessToken, { path: '/' })
      cookies.set('accessToken', state?.payload?.responseData?.data?.accessToken, { path: '/' })
      cookies.set('refreshToken', state?.payload?.responseData?.data?.refreshToken, { path: '/' })
      navigation('/')
    }
    if (state?.error) {
      setLoading(state?.loading)
      setMessage(state?.error)
    }
  }, [state])

  const resendHandler = () => {
      dispatch(setAsInitial())
      setMessage('')
      setLoading(false)
      navigation('/login')
  }
  return (
    <div style={{ background: '#E4F1F8' }}>
      <StyledContainer>
        <StyledBox>
          <StyledPaper>
            <FormSection>
              <div style={{ width: '100%' }}>
                <Link
                  to="https://www.cscs.uk.com/"
                  sx={{
                    width: 146,
                    height: 146,
                    marginRight: (theme) => theme.spacing(3),
                  }}
                >
                  <Image
                    style={{ width: 100, height: 100 }}
                    src="/images/logos/logo@2x.png"
                    alt="CSCS smartcheck web app"
                  />
                </Link>
                <PageTitle>Code Verification</PageTitle>
                <StyleSubTitle>
                  Please enter the verification code you received on
                  {location?.search?.split('=')[1] ? (` ${decodeURIComponent(location?.search?.split('=')[1])}`) : ' your phone/email.'}
                </StyleSubTitle>
                <OtpVerificationInput getValue={handleChange} message={message} status={status} />
                <Button
                  variant="contained"
                  sx={{ mt: 2, mb: 1 }}
                  style={{
                    width: '100%',
                    maxWidth: '415px',
                    padding: '6px',
                    borderRadius: '4px',
                    background: '#0179C1',
                    color: '#FFF',
                    fontSize: '1.25rem',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    marginTop: '25px',
                    textTransform: 'none',
                  }}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
                </Button>
                <div style={{ display: 'flex', paddingTop: '10px' }}>
                  <Text>{"haven't"} received the code?</Text>
                  <LinkTextDiv onClick={resendHandler}> Resend here</LinkTextDiv>
                </div>
                <div style={{ width: '430px', height: 0, border: '1px white solid', marginTop: '52px' }}></div>
                <div style={{ color: '#858585', fontSize: 20, fontFamily: 'Calibri', width: '430px', fontWeight: '400', wordWrap: 'break-word', marginTop: '36px' }}>
                  Download the CSCS Smart Check app on mobile
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                <Link
                    to={ExtraLinks.appStore}
                    sx={{ width: '144px', height: '43px' }}
                    data-testid="play-store-btn"
                  ><img
                    src={'/images/app-store.png'}
                    alt="Login Visual"
                    style={{ width: 144, height: 43, background: 'white' }}
                  />
                  </Link>
                  <Link
                    to={ExtraLinks.playStore}
                    sx={{ width: '144px', height: '43px' }}
                    data-testid="play-store-btn"
                  >
                  <img
                    src={'/images/play-store.png'}
                    alt="Login Visual"
                    style={{
                      width: 144,
                      height: 43,
                      background: 'white',
                      marginLeft: '30px',
                    }}
                  />
                  </Link>
                </div>
              </div>
            </FormSection>
            <ImageSection>
              <img
                src={'/images/men-group.png'}
                alt="Login Visual"
                style={{
                  objectFit: 'contain',
                  width: '75%',
                  marginLeft: 'auto',
                  display: 'block',
                }}
              />
            </ImageSection>
          </StyledPaper>
        </StyledBox>
        <div style={{
          backgroundColor: '#ffffff',
          height: '62px',
          marginTop: '5rem',
          width: '94%'
        }}>
          <Footer IsLoginPage={true} bgColor="bw.white" iconColor='info' width="98%" />
        </div>
      </StyledContainer>
    </div>
  )
}

export default Verification
