import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, CircularProgress, Typography, } from '@mui/material'
import Captcha, { type ICaptchaRef } from 'src/components/Captcha'
import Footer from 'src/components/Footer'
import Image from 'src/components/Image'
import InfoComponent from 'src/components/InfoIcon'
import Link from 'src/components/Link'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import { ExtraLinks } from 'src/utils/extraLinks'
import Cookies from 'universal-cookie'

import { LoginAuth } from './StateHandler/Actions'
import { setAsInitial } from './StateHandler/login.reducer'
import { ErrorText, FormSection, ImageBox, ImageSection, PageTitle, StyledBox, StyledContainer, StyledLable, StyledPaper, StyleSubTitle } from './styles'

const Login: React.FC = () => {
  const [emailNumber, setEmailNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [loading, setLoading] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')
  const [apiMessage, setApiMessage] = useState('')
  const captchaRef = useRef<ICaptchaRef>(null)
  const dispatch = useAppDispatch()
  const state: any = useAppSelect((state) => state.loginReducer)
  const cookies = new Cookies()
  const navigation = useNavigate()
  const validateInput = (value: string) => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    const isValidPhone = /^[0-9+\-\s]{10,15}$/.test(value)
    if (!isValidEmail && !isValidPhone) {
      setIsValid(false)
      setErrorMessage('Please enter a valid email or mobile number.')
    } else {
      setIsValid(true)
      setErrorMessage('')
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setEmailNumber(value)
    validateInput(value)
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!captchaToken) {
      alert('Please validate captcha')
      return
    }
    if (isValid) {
     const payload = { username: emailNumber, captchaToken }
     setLoading(true)
     dispatch(LoginAuth(payload))
    }
    resetForm()
  }
  useEffect(() => {
    if (state?.payload?.responseData?.data?.message) {
      setLoading(false)
      setApiMessage(state?.payload?.responseData?.data?.message)
      cookies.remove('isGuest')
      navigation(`/verification?username=${encodeURIComponent(emailNumber)}`)
    }
    if (state?.error) {
      setLoading(false)
      setApiMessage(state?.error)
    }
    return () => {
      dispatch(setAsInitial())
    }
  }, [state])

  const resetForm = () => {
    captchaRef.current?.resetCaptcha?.()
    // setEmailNumber('')
    setCaptchaToken('')
   }
  //  do for guest user login
  const gusetLoginHandler = () => {
    cookies.set('isGuest', 'true')
    navigation('/')
  }
  return (
    <div style={{ background: '#E4F1F8' }}>
       <StyledContainer>
        <StyledBox>
          <StyledPaper>
            <FormSection onSubmit={onSubmit}>
              <div style={{
 width: '100%',
              //  margin: '0 auto', paddingLeft: '14rem'
                }}>
                <Link
                  to="https://www.cscs.uk.com/"
                  sx={{
                    width: 146,
                    height: 146,
                    marginRight: (theme) => theme.spacing(3),
                  }}
                >
                  <Image
                  style={{ width: 100, height: 100 }}
                    src="/images/logos/logo@2x.png"
                    alt="CSCS smartcheck web app"
                  />
                </Link>
                <PageTitle>
                   Log In
                </PageTitle>
                <StyleSubTitle>
                  Login to start checking CSCS alliance cards
                </StyleSubTitle>
                <StyledLable>
                  Email / Mobile Number <InfoComponent/>
                </StyledLable>
                <input
                  type="text"
                  placeholder="Email / Mobile Number"
                  value={emailNumber}
                  onChange={handleInputChange}
                  style={{
                    width: '412px',
                    height: '25px',
                    background: 'white',
                    borderRadius: 4,
                    border: '1px solid #d3d3d3',
                    padding: '10px',
                  }}
                />
              {!isValid && emailNumber && <ErrorText>{errorMessage}</ErrorText>}
              <Captcha
                  styles={{ paddingTop: '20px', width: '412px' }}
                  ref={captchaRef}
                  onChange={(token) => {
                    setCaptchaToken(token || '')
                  }}
                  isLoading={loading}
                />
               <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 1 }}
                style={{
                  width: '433px',
                  padding: loading ? '12px' : '8px',
                  borderRadius: '4px',
                  background: '#0179C1',
                  color: '#FFF',
                  fontSize: '1.15rem',
                  fontWeight: 700,
                  marginTop: '25px',
                  textTransform: 'none',
                }}
                 disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Send Verification Code'}
              </Button>
                {apiMessage && <Typography color={'error'}>{apiMessage}</Typography>}
                <div style={{ width: '430px', height: 0, border: '1px white solid', marginTop: '38px' }}></div>
                <div
                  style={{
                    fontFamily: "Calibri, 'MetaProBold'",
                    fontSize: '1.15rem',
                    width: '433px',
                    fontWeight: 700,
                    marginTop: '15px',
                    textTransform: 'none',
                    color: '#0179C1',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={gusetLoginHandler}
                >
                  continue as guest
                </div>
                <div style={{ color: '#858585', fontSize: 20, fontFamily: 'Calibri', width: '430px', fontWeight: '400', wordWrap: 'break-word', marginTop: '30px' }}>Download the CSCS Smart Check app on mobile</div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                <Link
                    to={ExtraLinks.appStore}
                    sx={{ width: '144px', height: '43px' }}
                    data-testid="play-store-btn"
                  ><img
                    src={'/images/app-store.png'}
                    alt="Login Visual"
                    style={{ width: 144, height: 43, background: 'white' }}
                  />
                  </Link>
                  <Link
                    to={ExtraLinks.playStore}
                    sx={{ width: '144px', height: '43px' }}
                    data-testid="play-store-btn"
                  >
                  <img
                    src={'/images/play-store.png'}
                    alt="Login Visual"
                    style={{
                      width: 144,
                      height: 43,
                      background: 'white',
                      marginLeft: '30px',
                    }}
                  />
                  </Link>
                </div>
              </div>
            </FormSection>
            <ImageSection>
              <ImageBox
                src={'/images/boy-with-cam.png'}
                alt="Login Visual" />
            </ImageSection>
          </StyledPaper>
        </StyledBox>
       <div style = {{
              backgroundColor: '#ffffff',
              height: '62px',
              marginTop: '5rem',
             }}>
        <Footer IsLoginPage={true} bgColor="bw.white" iconColor='info' width="98%" />
        </div>
      </StyledContainer>
     </div>
   )
}

export default Login
